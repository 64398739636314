import React from 'react';
/*import ReactDOM from 'react-dom';*/

import logo from '../img/logo-stimulus-HD.jpg';
import LanguageChange from './LanguageChange'
import Utils from './Utils'

class HeaderOff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langlist:[]
    };

  }

  componentDidMount = async () => {
    const r1 = await Utils.languesliste(this.state.token);

    if(r1 !== false)
    {
      this.setState({langlist: r1});
    }
  }

  render() {
    return (
        <header className="pt-3 pb-3 mb-3 App-header">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between">
            <a href="/" className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
              <img src={logo} className='logo' alt="Logo Stimulus" />
            </a>

            {/*
            <div className="col-12 col-sm-auto mb-3 mb-sm-0 me-sm-3">
              <LanguageChange langlist={this.state.langlist} />
            </div>
            */}

          </div>
        </div>
      </header>);
  }
}


export default HeaderOff;