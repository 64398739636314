export default {

  async signin(login, pwd) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/auth/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          login: login,
          pwd: pwd,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('token'))
        {
          return json;
        } else {
          return false;
        }
      } else {
        return false;
      }
      
    } catch (error) {
      return false; // A rebasculer en false
    }
  },
 
  async paysliste(id_client, token) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/paysliste/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client: id_client,
          token: token,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('records'))
        {
          return json.records;
        } else {
          return [];
        }
      } else {
        return [];
      }
      
    } catch (error) {
      return []; 
    }
  },

  async ficheliste(id_client, token, id_pays) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/ficheliste/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client: id_client,
          token: token,
          pays: id_pays,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('records'))
        {
          return json.records;
        } else {
          return [];
        }
      } else {
        return [];
      }
      
    } catch (error) {
      return []; 
    }
  },
  async docliste(id_client, token, id_pays) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/docliste/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          client: id_client,
          token: token,
          pays: id_pays,
          preprod:false
        })
      });

      

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('records'))
        {
          return json.records;
        } else {
          return [];
        }
      } else {
        return [];
      }
      
    } catch (error) {
      return []; 
    }
  },
  async languesliste(token) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/langues/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        })
      });

      

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('records'))
        {
          return json.records;
        } else {
          return [];
        }
      } else {
        return [];
      }
      
    } catch (error) {
      return []; 
    }
  },
  async client(id_client, token) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/client/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          client: id_client,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('id'))
        {
          return json;
        } else {
          return [];
        }
      } else {
        return [];
      }
      
    } catch (error) {
      return []; 
    }
  },
  async recherche(token, recherche) {
    try {
      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/search/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          recherche: recherche,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===200)
      {
        let json = await response.json();
        if(json.hasOwnProperty('message'))
        {
          return [];
        } else {
          return json.records;
        }
      } else {
        return [];
      }
      
    } catch (error) {
      return []; 
    }
  },
  async sendmsg(token, id_user, id_pays, es, me, lfn, ph, em, ch, client, phmsg, clientemail) {
    try {

      if(!ch)
      {
        return "Your message has NOT been sent to our team. Please agree conditions."
      }

      if (!lfn) {
        return "Your message has NOT been sent to our team. You should give a pseudo or an email."
      }

      if (!me) {
        return "Your message has NOT been sent to our team. You should leave a message."
      }

      if (!ph && !em) {
        return "Your message has NOT been sent to our team. You should choose if you want to be contacted by phone or by email or both."
      }



      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/sendmsg/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          user: id_user,
          client: client,
          pays: id_pays,
          sujet: es,
          message: me,
          nom: lfn,
          tel: ph,
          phmsg: phmsg,
          email: em,
          clientemail: clientemail,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===201)
      {
        return true;
      } else if(rcode===400)
      {
        let json = await response.json();
        if(json.hasOwnProperty('msg'))
        {
          return json.msg;
        } else {
          return "Your message has NOT been sent to our team. Please check fields.";
        }
      } else {
        return "Your message has NOT been sent to our team. Please check fields.";
      }
      
    } catch (error) {
      return "Your message has NOT been sent to our team. Please check fields."; 
    }
  },
  async sendquestion(token, q0, q2, q3_date, q3_time, q4, q5, q6, q7, q8, q9, q10, clientemail) {
    try {

      const apihost="https://api.portaileap.com";
      let response = await fetch(
        apihost + '/sendquestion/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          q1: q0,
          q2: q2,
          q3_date: q3_date,
          q3_time: q3_time,
          q4: q4,
          q5: q5,
          q6: q6,
          q7: q7,
          q8: q8,
          q9: q9,
          q10: q10,
          clientemail: clientemail,
          preprod:false
        })
      });

      let rcode = await response.status;
      if(rcode===201)
      {
        return true;
      } else if(rcode===400)
      {
        let json = await response.json();
        if(json.hasOwnProperty('msg'))
        {
          return json.msg;
        } else {
          return "Your feedback has NOT been sent to our team. Please check fields.";
        }
      } else {
        return "Your feedback has NOT been sent to our team. Please check fields.";
      }
      
    } catch (error) {
      return "Your feedback has NOT been sent to our team. Please check fields."; 
    }
  }

}