import React from 'react';
/*import ReactDOM from 'react-dom';*/

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


class ContactScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSendMsgClick = this.handleSendMsgClick.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleChange5 = this.handleChange5.bind(this);
    this.handleChange6 = this.handleChange6.bind(this);
    this.handleChange7 = this.handleChange7.bind(this);
    this.state = {ln: '', fn: '', ph: '', phmsg: '', em: '', es: '', me: '', ch:false, alertmsg:'', okphone:false, okemail:false, okleave:false};

  }
  handleChange1(event) {
    this.setState({ln: event.target.value});
  }
  handleChange2(event) {
    this.setState({fn: event.target.value});
  }
  handleChange3(event) {
    this.setState({ph: event.target.value});
  }
  handleChange4(event) {
    this.setState({em: event.target.value});
  }
  handleChange5(event) {
    this.setState({es: event.target.value});
  }
  handleChange6(event) {
    this.setState({me: event.target.value});
  }
  handleChange7(event) {
    this.setState({ch: event.target.checked});
  }

  handleChangeokemail(e) {
    this.setState({okemail: e});
  };
  handleChangeokphone(e) {
    this.setState({okphone: e});
  };

  handleChangephmsg(e) {
    this.setState({okleave: e});
    if(e)
    {
      this.setState({phmsg: 'Yes, you can leave me a message'});
    } else {
      this.setState({phmsg: 'No, please do not leave me a message'});
    }
    
  };

  handleSendMsgClick(e) {
    this.props.onSendMsgClick(this.state.ln, this.state.fn, this.state.ph, this.state.em, this.state.es, this.state.me, this.state.ch, this.state.phmsg);
  }
  render() {
    const showalertcontactdanger = this.props.showalertcontactdanger;
    const showalertcontactdanger_msg = this.props.showalertcontactdanger_msg;
    const showalertcontactsuccess = this.props.showalertcontactsuccess;
    return (
        <div>
          <section className="p-3 mb-3 Welcome2">
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100">
                    <h1 className="mb-3">Contact us</h1>

                    <p>To contact our business development team, please complete the following information :</p>
              </div>
            </div>
          </section>

          <section className="p-3 mb-3 Welcome2">
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100">
                    <p className="mb-3">If you do not know your access code, please call the portal's technical support department using the number for your country.</p>
              

                    <main className="form-contact mb-3">
                      <form>

                        <div className="mb-3 form-floating">
                          <input type="email" className="form-control" id="floatingInput2" placeholder="Name / Firstname or Pseudo" onChange={this.handleChange2} />
                          <label htmlFor="floatingInput2">Name / Firstname or Pseudo</label>
                        </div>
                        <div className="mb-3 form-floating">
                          <textarea rows={25} cols={25} className="form-control" id="floatingInput6" placeholder="Provide details: what are you loàoking for or what issues would you want to address?" onChange={this.handleChange6} />
                          <label htmlFor="floatingInput6">Provide details: what are you looking for or what issues would you want to address?</label>
                        </div>
                        <div className="mb-3">
                          How can we follow up with you?
                          <div className="form-check form-check-inline">
                          <Form.Check
                            inline
                            label="by phone"
                            name="ch1"
                            type="checkbox"
                            id="fuPhone"
                            value="okphone"
                            checked={this.state.okphone === true}
                            onChange={(e) => {
                              this.handleChangeokphone(e.target.checked);
                            }}
                          />
                          <Form.Check
                            inline
                            label="by email"
                            name="ch1"
                            type="checkbox"
                            id="fuEmail"
                            value="okemail"
                            checked={this.state.okemail === true}
                            onChange={(e) => {
                              this.handleChangeokemail(e.target.checked);
                            }}
                          />
                          </div>
                        </div>
                        {this.state.okphone &&
                        <div>
                          <div className="mb-3 form-floating">
                            <PhoneInput
                              country={'us'}
                              inputClass={'form-control'}
                              specialLabel={'Phone'}
                              value={this.state.ph}
                              onChange={ph => this.setState({ ph })}
                              inputProps={{
                                name: 'floatingInput3',
                                id: 'floatingInput3'
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            May we leave you a message?
                            <div className="form-check form-check-inline">
                            <Form.Check
                              inline
                              label="yes"
                              name="ch2"
                              type="checkbox"
                              id="leaveMsg"
                              value="okleave"
                              checked={this.state.okleave === true}
                              onChange={(e) => {
                                this.handleChangephmsg(e.target.checked);
                              }}
                            />
                            </div>
                          </div>
                        </div>
                        }
                        {this.state.okemail &&
                        <div className="mb-3 form-floating">
                          <input type="email" className="form-control" id="floatingInput4" placeholder="Email" onChange={this.handleChange4} />
                          <label htmlFor="floatingInput4">Email</label>
                        </div>
                        }
                        <div className="form-group form-check mb-3">
                          <input type="checkbox" className="form-check-input" id="agreeCheck" onChange={this.handleChange7} />
                          <label className="form-check-label" htmlFor="agreeCheck">I understand that the data I provided will be used to contact me. More information on our <a href="https://stimulus-global.com/data-protection-policy/" target="_blank">Privacy Policy</a>.</label>
                        </div>
                        <button className="mb-3 w-100 btn btn-lg btn-primary" type="button" onClick={this.handleSendMsgClick}>Contact us</button>
                      </form>
                    </main>
                      <Alert className="notranslate" key="alert_5" variant="danger" show={showalertcontactdanger}>
                        {showalertcontactdanger_msg}
                      </Alert>
                      <Alert className="notranslate" key="alert_1" variant="success" show={showalertcontactsuccess}>
                        Thank you for your message, we will come back to you shortly.<br />
                        <strong>Should you need immediate support, please contact our services by phone.</strong>
                      </Alert>
                    

              </div>
            </div>
          </section>


          <div className="container">
            <p className="mb-3">{/* This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility. */}</p>
          </div>
        </div>
      );
  }
}


export default ContactScreen;