import React from 'react';
/*import ReactDOM from 'react-dom';*/

import woman from '../img/bh2.png';
import maintel from '../img/tel.png';
import email from '../img/mail.png';
import alttel from '../img/monde.png';

import noimgpdf from '../img/42.png';
import noimgpodcast from '../img/39.png';
import noimgvideo from '../img/40.png';

import phonebandeau from '../img/Phone-Bandeau.png';


import Accordion from 'react-bootstrap/Accordion';
import PracticalInformations from './PracticalInformations'


class LoggedScreen extends React.Component {
  constructor(props) {
    super(props);

    this.handleReadMoreClick = this.handleReadMoreClick.bind(this);
    this.handleReadMoreClick2 = this.handleReadMoreClick2.bind(this);
    this.handleContactUsClick = this.handleContactUsClick.bind(this);
  }

  handleReadMoreClick(e) {
    this.props.onReadMoreClick(e.currentTarget.getAttribute('data-value'), this.props.fiches);
  }

  handleReadMoreClick2(id, fiches) {
    this.props.onReadMoreClick2(id, fiches);
  }

  handleContactUsClick(e) {
    e.preventDefault();
    this.props.onContactUsClick();
  }

  render() {
    const cemail = this.props.cemail;
    const infos = this.props.infos;
    const fiches = this.props.fiches;
    const docs = this.props.docs;
    const preprodfiches="";
    //const preprod="preprod-";
    const preprod="";
    let array_fiches = fiches.reverse().filter(item => item.alaune === 0);
    let length_fiches = array_fiches.length;

    const imgmsg = {
      maxHeight: '30px',
    };
    const imgtel = {
      maxHeight: '34px',
    };
    const imgworld = {
      maxHeight: '60px',
    };
    const imgtelmain = {
      maxHeight: '60px',
    };
    return (
        <div>
          <section className=" pt-0 p-4 LoggedScreen1">
            <div className="h-100">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-sm-6 col-12 my-auto mb-1 mb-sm-0">
                    <img src={woman} alt="Main information" />
                  </div>
                  <div className="col-xl-5 col-sm-6 col-12 bgblue p-1 ">
                    <main className="d-flex align-items-center justify-content-center flex-column h-100">

                      <div className="row w-100 mb-1 ">
                        <div className="col-3 my-auto mx-auto text-center">
                          <img style={imgtelmain} src={maintel} alt="Main phonenumber" />
                        </div>
                        <div className="col-9 my-auto">
                          <p className="text-uppercase white">Anonymous & Confidential</p>
                          <h2 className="notranslate yellow" key="mainphone">{infos[0].numero_vert}</h2>
                        </div>
                      </div>
                      <div className="row w-100">
                        <div className="col-3 my-auto mx-auto text-center">
                          {( (infos[0].numero_alternatif !== undefined && infos[0].numero_alternatif!="" && infos[0].numero_alternatif!="0") ) &&
                          <img style={imgworld} src={alttel} alt="Alternative phonenumber" />
                          }
                        </div>
                        <div className="col-9 my-auto">
                          <p className="white" key="altphone"><span className="notranslate">{infos[0].numero_alternatif}</span></p>
                        </div>
                      </div>
                      {/*
                      <div className="row w-100 mt-1">
                        <div className="col-3 my-auto mx-auto text-center">
                          <img style={imgmsg} src={email} alt="Send a message" />
                        </div>
                        <div className="col-9 my-auto fs-80">
                          <a href={"mailto:"+cemail} className="white">Send a message</a>
                        </div>
                      </div>
                      */}

                      {/* <small>NOTE: Please confirm your location using the location dropdown at the top of the page before contacting our team.</small> */}
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {fiches.length > 0 &&
              fiches.slice(0, 1).map(function (item) {
                if (item.alaune=="1") {
                return(
                  
                      <section className="p-4 LoggedScreen2 bggreylight" key={"alaune_"+item.id}>
                        <div className="h-100">
                          <div className="container">
                            <div className="row">
                              <div className="col-7">
                                <main className="mb-3">
                                  {( (item.pdf !== "") && (item.pdf)) &&
                                  <a href={"https://"+preprodfiches+"erp.psya.fr"+item.pdf} target="_blank">
                                    <h2 className="mb-3">{item.titre}</h2>
                                  </a>
                                  }
                                  {( (item.podcast !== "") && (item.podcast)) &&
                                  <a href={item.podcast} target="_blank">
                                    <h2 className="mb-3">{item.titre}</h2>
                                  </a>
                                  }
                                  {( (item.video !== "") && (item.video)) &&
                                  <a href={item.video} target="_blank">
                                    <h2 className="mb-3">{item.titre}</h2>
                                  </a>
                                  }

                                  {( ( (item.video === "") || (!item.video) ) && ( (item.podcast === "") || (!item.podcast) ) && ( (item.pdf === "") || (!item.pdf) ) ) &&
                                  <a href={"https://"+preprodfiches+"erp.psya.fr"+item.img} target="_blank">
                                    <h2 className="mb-3">{item.titre}</h2>
                                  </a>
                                  }
                                  
                                  <div className="col-12"><small>{item.texte.substring(0, 200)}...</small></div>
                                </main>
                              </div>  
                                          
                              <div className="col-5 text-end">
                                {( (item.pdf !== "") && (item.pdf)) &&
                                <a href={"https://"+preprodfiches+"erp.psya.fr"+item.pdf} target="_blank">
                                  <img src={"https://"+preprodfiches+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} altsrc={noimgpdf}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=currentTarget.getAttribute("altsrc");
                                  }}
                                   />
                                </a>
                                }
                                {( (item.podcast !== "") && (item.podcast)) &&
                                <a href={item.podcast} target="_blank">
                                  <img src={"https://"+preprodfiches+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} altsrc={noimgpodcast}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=currentTarget.getAttribute("altsrc");
                                  }}
                                   />
                                </a>
                                }
                                {( (item.video !== "") && (item.video)) &&
                                <a href={item.video} target="_blank">
                                  <img src={"https://"+preprodfiches+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} altsrc={noimgvideo}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src=currentTarget.getAttribute("altsrc");
                                  }}
                                   />
                                </a>
                                }

                                {( ( (item.video === "") || (!item.video) ) && ( (item.podcast === "") || (!item.podcast) ) && ( (item.pdf === "") || (!item.pdf) ) ) &&
                                <a href={"https://"+preprodfiches+"erp.psya.fr"+item.img} target="_blank">
                                  <img src={"https://"+preprodfiches+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} />Test
                                </a>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                );
                }

              }, this)
          }

          <PracticalInformations fiches={fiches} ReadMoreClick2={this.handleReadMoreClick2} />

          {docs.length > 0 &&
          <section className="p-4 LoggedScreen3 bggreylight">
            <div className="h-100">
              <div className="container">
                <h2 className="mb-3 blue">Resources</h2>

                <Accordion defaultActiveKey={['0']} alwaysOpen>
                {
                  docs.map(function (item) {

                    return(
                      <Accordion.Item eventKey={item.id} key={"uniqueId_"+item.id}>
                        <Accordion.Header bsPrefix="accordion-header-custom">{item.name}</Accordion.Header>
                        <Accordion.Body>
                          <div className="row justify-content-between">
                          {
                            item.docs.map(function (item1) {
                              let comp1;
                              if ( (item1.pdf !== "") && (item1.pdf) ) {
                                comp1=<a href={"https://"+preprod+"erp.psya.fr"+item1.pdf} target="_blank" rel="noreferrer" className="notranslate">{item1.name}</a>
                              } else if ( (item1.podcast !== "") && (item1.podcast) ) {
                                comp1=<a href={item1.podcast} target="_blank" rel="noreferrer" className="notranslate">{item1.name}</a>
                              } else if ( (item1.video !== "") && (item1.video) ) {
                                comp1=<a href={item1.video} target="_blank" rel="noreferrer" className="notranslate">{item1.name}</a>
                              } else if ( (item1.lien_documentation !== "") && (item1.lien_documentation) ) {
                                comp1=<a href={item1.lien_documentation} target="_blank" rel="noreferrer" className="notranslate">{item1.name}</a>
                              } 
                              return(
                                <div className="col-12 col-sm-5 mb-2 p-2 docdiv" key={"uniqueIId_"+item1.id}>
                                {comp1}
                                </div>
                              );
                            }, this)
                          }
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  }, this)
                }
                </Accordion>
              </div>
            </div>
          </section>
          }

          {/*
          <section className="p-4 LoggedScreen5 mb-3">
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100">
                    <h1 className="mb-3">Get in touch</h1>

                    <button className="mt-3 ps-5 pe-5 btn btn-lg btn-primary" type="button" onClick={this.handleContactUsClick}>Contact</button>
              </div>
            </div>
          </section>
          */}

          <div className="callus my-auto d-flex justify-content-center position-fixed pt-1 pb-1">
            <p className="my-auto"><img src={phonebandeau} style={imgtel} className="me-4" /> <span className="notranslate yellow" key="mainphone"> {infos[0].numero_vert} </span></p>
          </div>
        </div>
      );
  }
}


export default LoggedScreen;