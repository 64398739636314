import React from 'react';
/*import ReactDOM from 'react-dom';*/

import Alert from 'react-bootstrap/Alert';

class Welcome3 extends React.Component {
  constructor(props) {
    super(props);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.state = {
      scy: "",
      cylistw3:this.props.cylist
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ scy: e.target.value });
  }

  handleNextClick(e) {

    this.props.onButtonClick(this.state.scy, this.state.cylistw3);
  }
  render() {
    const cylist=this.props.cylist;
    const showalert3 = this.props.showalert3;
    if (cylist.length > 0) {
      return (
          <section className="p-3 Welcome3 pb-0">
            <div className="container w-75 mt-1 yhrdiv"></div>
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100 w-75">

                    <main className="form-signin">
                      <form>
                        <div className="row">
                          <div className="col-11 mx-auto">
                            <div className="row">
                              <div className="pb-1 col-12 col-md-2 my-auto mx-auto blue">Select your country</div>
                              <div className="pb-1 col-12 col-md-4 my-auto mx-auto">
                                <select className="form-control" id="floatingCountry" onChange={this.handleChange}>
                                  <option value="">Select your country</option>
                                  
                                    {
                                      cylist.map(function (item) {
                                        return <option key={"kk_"+item.id} value={item.id}>{item.name}</option>
                                      })
                                    }
                                </select>
                                <label htmlFor="floatingCountry" className="d-none">Select your country</label>
                              </div>
                              <div className="pb-1 col-12 col-md-4 my-auto mx-auto">
                               <button className="w-100 btn btn-sm btn-primary" type="button" onClick={this.handleNextClick}>Log in</button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </form>
                    </main>
                    <Alert key="0" variant="danger" show={showalert3}>
                      You have to choose a country.
                    </Alert>
                    {/* <p className="mb-3">This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility.</p> */}
              
              </div>
            </div>
          
          </section>
      );
    }
    return (
      <div className="container">
        <Alert key="0" variant="danger">
          No country associated with your account was found. Please contact our team.
        </Alert>
      </div>
    );
  }
}


export default Welcome3;