import React from 'react';
import ReactGA from 'react-ga4';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";


/*import ReactDOM from 'react-dom';*/


import './App.css';

import HeaderOff from './components/HeaderOff'
import HeaderOn from './components/HeaderOn'
import Welcome1 from './components/Welcome1'
import Welcome2 from './components/Welcome2'
import Welcome3 from './components/Welcome3'
import LoggedScreen from './components/LoggedScreen'
import ContactScreen from './components/ContactScreen'
import QuestionScreen from './components/QuestionScreen'
import ArticleScreen from './components/ArticleScreen'
import SearchScreen from './components/SearchScreen'
import Mentions from './components/Mentions'
import FooterApp from './components/FooterApp'

import Utils from './components/Utils'

ReactGA.initialize('G-0C6F4XX1XS');

const handleAcceptCookie = () => {

};

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};

function ShowFooter(props) {
  const isLoggedIn = props.isLoggedIn;
  const step2 = props.step2;
  const handleContactUsClick = props.handleContactUsClick;
  const handleQuestionClick = props.handleQuestionClick;
  const handleMentionsClick = props.handleMentionsClick;

  return <FooterApp step2={step2} isLoggedIn={isLoggedIn} onQuestionClick={handleQuestionClick} onContactUsClick={handleContactUsClick} onMentionsClick={handleMentionsClick} />;
}

function ShowHeader(props) {
  const isLoggedIn = props.isLoggedIn;
  const handleLogoutClick = props.handleLogoutClick;
  const handleContactUsClick = props.handleContactUsClick;
  const handleGoBackClick = props.handleGoBackClick;
  const handleSearchClick = props.handleSearchClick;
  const handleChangeLocationClick = props.handleChangeLocationClick;

  const infos = props.infos;
  const clogo = props.clogo;
  const curl = props.curl;
  const iuser = props.iuser;
  const cylist = props.cylist;
  const showmain = props.showmain;
  const showmentions = props.showmentions;
  const showcontact = props.showcontact;
  const showquestion = props.showquestion;
  const showarticle = props.showarticle;
  const showsearch = props.showsearch;
  const token = props.token;
  const handleChangeCountryClick = props.handleChangeCountryClick;
  if (isLoggedIn) {
    if (showmentions) {
      return <div className="container-fluid p-0 position-absolute ydiv" id="Ydiv"></div>;
    }
    return <HeaderOn curl={curl} clogo={clogo} token={token} showmain={showmain} showcontact={showcontact} showarticle={showarticle} showsearch={showsearch} onSearchClick={handleSearchClick} onGoBackClick={handleGoBackClick} onContactUsClick={handleContactUsClick} onChangeLocationClick={handleChangeLocationClick} onButtonClick={handleLogoutClick} onChangeCountryClick={handleChangeCountryClick} infos={infos} cylist={cylist} iuser={iuser} />;
  }
  if(getCookieConsentValue())
  {
    ReactGA.send({ hitType: "pageview", page: "/LoggedOff" });
  }
  
  return <HeaderOff />;
}

function ShowWelcome1(props) {
  const isLoggedIn = props.isLoggedIn;
  const showmentions = props.showmentions;
  if (isLoggedIn) {
    return <div></div>;
  }

  if (showmentions) {
    return <div></div>;
  }

  window.scrollTo({
    top: 0, 
    behavior: 'smooth'
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });

  return <Welcome1 />;
} 

function ShowWelcomeStep(props) {
  const isLoggedIn = props.isLoggedIn;
  const step2 = props.step2;
  const showalert2 = props.showalert2;
  const showalert3 = props.showalert3;
  const handleSignInClick = props.handleSignInClick;
  const handleNextClick = props.handleNextClick;
  const cylist=props.cylist;
  const showmentions = props.showmentions;
  if (isLoggedIn) {
    return <div />;
  }
  if (showmentions) {
    return <div></div>;
  }
  if (step2) {
    return <Welcome3 isLoggedIn={isLoggedIn} onButtonClick={handleNextClick} cylist={cylist} showalert3={showalert3}  />;
  }

  window.scrollTo({
    top: 0, 
    behavior: 'smooth'
    /* you can also use 'auto' behaviour
       in place of 'smooth' */
  });

  return <Welcome2 onButtonClick={handleSignInClick} showalert2={showalert2} />;
}

function ShowLoggedScreen(props) {
  const cemail = props.cemail;
  const isLoggedIn = props.isLoggedIn;
  const handleContactUsClick = props.handleContactUsClick;
  const handleReadMoreClick = props.handleReadMoreClick;
  const handleReadMoreClick2 = props.handleReadMoreClick2;
  const infos = props.infos;
  const fiches = props.fiches;
  const docs = props.docs;
  const showmain = props.showmain;
  if (isLoggedIn & showmain) {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });

    if(getCookieConsentValue())
    {
      ReactGA.send({ hitType: "pageview", page: "/LoggedIn" });
    }
    return <LoggedScreen cemail={cemail} infos={infos} fiches={fiches} docs={docs} onContactUsClick={handleContactUsClick} onReadMoreClick={handleReadMoreClick} onReadMoreClick2={handleReadMoreClick2} />;
  }
  return <div></div>;
} 

function ShowContactScreen(props) {
  const isLoggedIn = props.isLoggedIn;
  const showcontact = props.showcontact;
  const showalertcontactdanger = props.showalertcontactdanger;
  const showalertcontactdanger_msg = props.showalertcontactdanger_msg;
  const showalertcontactsuccess = props.showalertcontactsuccess;
  const handleSendMsgClick = props.handleSendMsgClick;
  if (isLoggedIn & showcontact) {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
    return <ContactScreen onSendMsgClick={handleSendMsgClick} showalertcontactdanger={showalertcontactdanger} showalertcontactdanger_msg={showalertcontactdanger_msg} showalertcontactsuccess={showalertcontactsuccess} />;
  }
  return <div></div>;
}

function ShowQuestionScreen(props) {
  const isLoggedIn = props.isLoggedIn;
  const showquestion = props.showquestion;
  const showalertquestiondanger = props.showalertquestiondanger;
  const showalertquestiondanger_msg = props.showalertquestiondanger_msg;
  const showalertquestionsuccess = props.showalertquestionsuccess;
  const handleSendQuestionClick = props.handleSendQuestionClick;
  if (isLoggedIn & showquestion) {

    return <QuestionScreen onSendQuestionClick={handleSendQuestionClick} showalertquestiondanger={showalertquestiondanger} showalertquestiondanger_msg={showalertquestiondanger_msg} showalertquestionsuccess={showalertquestionsuccess} />;
  }
  return <div></div>;
} 

function ShowMentionsScreen(props) {
  const showmentions = props.showmentions;
  const isLoggedIn = props.isLoggedIn;
  const handleGoBackClick = props.handleGoBackClick;
  if (showmentions) {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
    return <Mentions isLoggedIn={isLoggedIn} onGoBackClick={handleGoBackClick} />;
  }
  return <div></div>;
} 

function ShowArticleScreen(props) {
  const isLoggedIn = props.isLoggedIn;
  const infos = props.infos;
  const fiches = props.fiches;
  const showarticle = props.showarticle;
  const article = props.article;
  if (isLoggedIn & showarticle) {
    return <ArticleScreen infos={infos} fiches={fiches} article={article} />;
  }
  return <div></div>;
} 

function ShowSearchScreen(props) {
  const isLoggedIn = props.isLoggedIn;
  const infos = props.infos;
  const fiches = props.fiches;
  const showsearch = props.showsearch;
  const results = props.results;
  const query = props.query;
  const handleReadMoreClick2 = props.handleReadMoreClick2;
  if (isLoggedIn & showsearch) {
    return <SearchScreen infos={infos} fiches={fiches} results={results} query={query} ReadMoreClick2={handleReadMoreClick2} />;
  }
  return <div></div>;
} 

class App extends React.Component {

  constructor(props) {
    super(props);
    this.SignInClick = this.handleSignInClick.bind(this);
    this.NextClick = this.handleNextClick.bind(this);
    this.LogoutClick = this.handleLogoutClick.bind(this);
    this.onChangeLocationClick = this.handleChangeLocationClick.bind(this);
    this.ContactUsClick = this.handleContactUsClick.bind(this);
    this.QuestionClick = this.handleQuestionClick.bind(this);
    this.SearchClick = this.handleSearchClick.bind(this);
    this.GoBackClick = this.handleGoBackClick.bind(this);
    this.SendMsgClick = this.handleSendMsgClick.bind(this);
    this.SendQuestionClick = this.handleSendQuestionClick.bind(this);
    this.ChangeCountryClick = this.handleChangeCountryClick.bind(this);
    this.ReadMoreClick = this.handleReadMoreClick.bind(this);
    this.ReadMoreClick2 = this.handleReadMoreClick2.bind(this);
    this.handleMentionsClick = this.handleMentionsClick.bind(this);

    this.state = {isLoggedIn: false, step2:false, showalert2:false, showalert3:false, showalertcontactdanger:false, showalertcontactdanger_msg:'', showalertcontactsuccess:false, showalertquestiondanger:false, showalertquestiondanger_msg:'', showalertquestionsuccess:false, showmain:false, showcontact:false, showarticle:false, showsearch:false, showmentions:false, token:'', id_user:'', id_client:'', iuser:[], cylist:[], infos:[], fiches:[], docs:[], article:[], results:[], query:'', cemail:'', clogo:'', curl:'', client:''};
  }



  handleSignInClick = async (login, pwd) => {
    const r0 = await Utils.signin(login, pwd);

    if(r0 !== false)
    {
      const r1 = await Utils.paysliste(r0.id_client, r0.token);
      if(r1 !== false)
      {
        const r2 = await Utils.client(r0.id_client, r0.token);
        this.setState({isLoggedIn: false, step2: true, showalert2:false, token:r0.token, id_user:r0.id_user, id_client:r0.id_client, cylist:r1, iuser:r0, cemail:r2.email, clogo:r2.image, curl:r2.link, client:r2.name});
      }
    } else {
      this.setState({isLoggedIn: false, step2: false, showalert2:true});
    }
    
  };

  handleNextClick = async (cy, cylistw3) => {
    if (!!(cy)) {
      
      var inf = cylistw3.filter(item => {
        return item.id === cy
      })

      const r2 = await Utils.ficheliste(this.state.id_client, this.state.token, cy);
      const r3 = await Utils.docliste(this.state.id_client, this.state.token, cy);
      
      this.setState({isLoggedIn: true, step2: false, showalert2:false, infos:inf, fiches:r2, docs:r3, showmain: true, showmentions:false, showquestion:false});
    } else {
      this.setState({showalert3:true});
    }

  }

  handleChangeCountryClick = async (cy, cylistw3) => {
    if (!!(cy)) {
      
      var inf = cylistw3.filter(item => {
        return item.id === cy
      })

      const r3 = await Utils.docliste(this.state.id_client, this.state.token, cy);

      this.setState({infos:inf, docs:r3});
    }
  }

  handleReadMoreClick = async (aid, articlelist) => {
    if (!!(aid)) {
      
      var inf = articlelist.filter(item => {
        return item.id === aid
      })

      this.setState({article:inf, showmain:false, showcontact:false, showarticle:true, showsearch:false, showmentions:false, showquestion:false});
    }
  }

  handleReadMoreClick2 = async (aid, articlelist) => {
    if (!!(aid)) {
      
      var inf = articlelist.filter(item => {
        return item.id === aid
      })

      this.setState({article:inf, showmain:false, showcontact:false, showarticle:true, showsearch:false, showmentions:false, showquestion:false});
    }
  }

  handleLogoutClick() {
    this.setState({isLoggedIn: false, step2: false, showalert2:false, showmain:false, showcontact:false, showarticle:false, showmentions:false, showsearch:false, cemail:'', clogo:'', showquestion:false});
  }

  handleChangeLocationClick = async (iuser) => {
    const r1 = await Utils.paysliste(iuser.id_client, iuser.token);
    this.setState({isLoggedIn: false, step2: true, showalert2:false, token:iuser.token, id_user:iuser.id_user, id_client:iuser.id_client, cylist:r1});
  };

  handleContactUsClick() {
    this.setState({showmain:false, showcontact:true, showquestion:false, showarticle:false, showsearch:false, showmentions:false});
  }

  handleQuestionClick() {
    this.setState({showmain:false, showcontact:false, showquestion:true, showarticle:false, showsearch:false, showmentions:false});
  }

  handleSearchClick = async (query) => {
    const r3 = await Utils.recherche(this.state.token, query);
    this.setState({showmain:false, showcontact:false, showarticle:false, showsearch:true, results:r3, query:query, showmentions:false, showquestion:false});
  }

  handleGoBackClick() {
    this.setState({showmain:true, showcontact:false, showarticle:false, showquestion:false, showsearch:false, showmentions:false});
  }


  handleSendMsgClick = async (ln, fn, ph, em, es, me, ch, phmsg) => {

    const r1 = await Utils.sendmsg(this.state.token, this.state.id_user, this.state.infos[0].name, es, me, ln+''+fn, ph, em, ch, this.state.client, phmsg, this.state.cemail);

    if(r1 === true)
    {
      this.setState({showalertcontactdanger: false, showalertcontactsuccess: true});
    } else {
      this.setState({showalertcontactdanger: true, showalertcontactdanger_msg: r1, showalertcontactsuccess: false});
    }
  }

  handleSendQuestionClick = async (q0, q2, q3_date, q3_time, q4, q5, q6, q7, q8, q9, q10) => {

    const r1 = await Utils.sendquestion(this.state.token, q0, q2, q3_date, q3_time, q4, q5, q6, q7, q8, q9, q10, this.state.cemail);

    if(r1 === true)
    {
      this.setState({showalertquestiondanger: false, showalertquestionsuccess: true});
    } else {
      this.setState({showalertquestiondanger: true, showalertquestiondanger_msg: r1, showalertquestionsuccess: false});
    }
  }

  handleMentionsClick() {
    this.setState({showmain:false, showcontact:false, showquestion:false, showarticle:false, showsearch:false, showmentions:true});
  }


  componentDidMount() {

  }


  componentWillUnmount() {
  }

  render() {
    const isLoggedIn = this.state.isLoggedIn;
    const step2 = this.state.step2;
    const showalert2 = this.state.showalert2;
    const showalert3 = this.state.showalert3;
    const showalertcontactdanger = this.state.showalertcontactdanger;
    const showalertcontactdanger_msg = this.state.showalertcontactdanger_msg;
    const showalertcontactsuccess = this.state.showalertcontactsuccess;
    const showalertquestiondanger = this.state.showalertquestiondanger;
    const showalertquestiondanger_msg = this.state.showalertquestiondanger_msg;
    const showalertquestionsuccess = this.state.showalertquestionsuccess;
    const cylist = this.state.cylist;
    const iuser = this.state.iuser;
    const infos = this.state.infos;
    const fiches = this.state.fiches;
    const docs = this.state.docs;
    const showmain = this.state.showmain;
    const showcontact = this.state.showcontact;
    const showquestion = this.state.showquestion;
    const showarticle = this.state.showarticle;
    const showsearch = this.state.showsearch;
    const showmentions = this.state.showmentions;
    const article = this.state.article;
    const results = this.state.results;
    const query = this.state.query;
    const token = this.state.token;
    const cemail = this.state.cemail;
    const clogo = this.state.clogo;
    const curl = this.state.curl;
    const client = this.state.client;

    return (
      <div className="App container-fluid p-0" id="Portaileap_App">
          {isLoggedIn ?
            <>
              <ShowHeader clogo={clogo} curl={curl} showmentions={showmentions} iuser={iuser} token={token} showmain={showmain} showcontact={showcontact} showarticle={showarticle} showsearch={showsearch} isLoggedIn={isLoggedIn} handleSearchClick={this.SearchClick} handleGoBackClick={this.GoBackClick} handleContactUsClick={this.ContactUsClick} handleChangeLocationClick={this.onChangeLocationClick} handleLogoutClick={this.LogoutClick} handleChangeCountryClick={this.ChangeCountryClick} infos={infos} cylist={cylist} />
              <ShowWelcome1 isLoggedIn={isLoggedIn} showmentions={showmentions} />
              <ShowWelcomeStep showmentions={showmentions} showalert2={showalert2} showalert3={showalert3} isLoggedIn={isLoggedIn} step2={step2} handleSignInClick={this.SignInClick}  handleNextClick={this.NextClick} cylist={cylist} />
              <ShowLoggedScreen cemail={cemail} isLoggedIn={isLoggedIn} iuser={iuser} infos={infos} fiches={fiches} docs={docs} showmain={showmain} handleContactUsClick={this.ContactUsClick} handleReadMoreClick={this.ReadMoreClick} handleReadMoreClick2={this.ReadMoreClick2}  />
              <ShowContactScreen handleSendMsgClick={this.SendMsgClick} isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showcontact={showcontact} showalertcontactdanger={showalertcontactdanger} showalertcontactdanger_msg={showalertcontactdanger_msg} showalertcontactsuccess={showalertcontactsuccess} />
              <ShowQuestionScreen handleSendQuestionClick={this.SendQuestionClick} isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showquestion={showquestion} showalertquestiondanger={showalertquestiondanger} showalertquestiondanger_msg={showalertquestiondanger_msg} showalertquestionsuccess={showalertquestionsuccess} />
              <ShowArticleScreen isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showarticle={showarticle} article={article} />
              <ShowSearchScreen isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showsearch={showsearch} results={results} query={query} handleReadMoreClick2={this.ReadMoreClick2} />
              <ShowMentionsScreen showmentions={showmentions} handleGoBackClick={this.GoBackClick} isLoggedIn={isLoggedIn} />
              <ShowFooter step2={step2} isLoggedIn={isLoggedIn} handleMentionsClick={this.handleMentionsClick} handleContactUsClick={this.ContactUsClick}  handleQuestionClick={this.QuestionClick} />
              <CookieConsent enableDeclineButton flipButtons declineButtonText="Refuse" buttonText="Understand" onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
                This site uses cookies from Google Analytics. These cookies are used to collect information and generate reports on the usage statistics of a site.
              </CookieConsent>
            </>
              :
            <>
              
              {/*<ShowHeader token={token} showmain={showmain} showcontact={showcontact} showarticle={showarticle} showsearch={showsearch} isLoggedIn={isLoggedIn} handleSearchClick={this.SearchClick} handleGoBackClick={this.GoBackClick} handleContactUsClick={this.ContactUsClick} handleChangeLocationClick={this.onChangeLocationClick} handleLogoutClick={this.LogoutClick} handleChangeCountryClick={this.ChangeCountryClick} infos={infos} cylist={cylist} />*/}
              <ShowWelcome1 isLoggedIn={isLoggedIn} showmentions={showmentions} />
              <ShowWelcomeStep showmentions={showmentions} showalert2={showalert2} showalert3={showalert3} isLoggedIn={isLoggedIn} step2={step2} handleSignInClick={this.SignInClick}  handleNextClick={this.NextClick} cylist={cylist} />
              <ShowLoggedScreen cemail={cemail} isLoggedIn={isLoggedIn} iuser={iuser} infos={infos} fiches={fiches} docs={docs} showmain={showmain} handleContactUsClick={this.ContactUsClick} handleReadMoreClick={this.ReadMoreClick} handleReadMoreClick2={this.ReadMoreClick2}  />
              <ShowContactScreen handleSendMsgClick={this.SendMsgClick} isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showcontact={showcontact} showalertcontactdanger={showalertcontactdanger} showalertcontactdanger_msg={showalertcontactdanger_msg} showalertcontactsuccess={showalertcontactsuccess} />
              <ShowQuestionScreen handleSendQuestionClick={this.SendQuestionClick} isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showquestion={showquestion} showalertquestiondanger={showalertquestiondanger} showalertquestiondanger_msg={showalertquestiondanger_msg} showalertquestionsuccess={showalertquestionsuccess} />
              <ShowArticleScreen isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showarticle={showarticle} article={article} />
              <ShowSearchScreen isLoggedIn={isLoggedIn} infos={infos} fiches={fiches} showsearch={showsearch} results={results} query={query} handleReadMoreClick2={this.ReadMoreClick2} />
              <ShowMentionsScreen showmentions={showmentions} handleGoBackClick={this.GoBackClick} isLoggedIn={isLoggedIn} />

              <ShowFooter step2={step2} isLoggedIn={isLoggedIn} handleMentionsClick={this.handleMentionsClick} handleContactUsClick={this.ContactUsClick}  handleQuestionClick={this.QuestionClick} />
              <CookieConsent enableDeclineButton flipButtons declineButtonText="Refuse" buttonText="Understand" onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
                This site uses cookies from Google Analytics. These cookies are used to collect information and generate reports on the usage statistics of a site.
              </CookieConsent>
            </>
          }

      </div>
    );
  }
}

export default App;
