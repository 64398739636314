import React from 'react';
/*import ReactDOM from 'react-dom';*/

import Alert from 'react-bootstrap/Alert';

class Welcome2 extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.state = {login: '', pwd: ''};
  }
  handleChange1(event) {
    this.setState({login: event.target.value});
  }
  handleChange2(event) {
    this.setState({pwd: event.target.value});
  }
  handleLoginClick(e) {
    this.props.onButtonClick(this.state.login, this.state.pwd);
  }

  render() {
    const showalert2 = this.props.showalert2;
    return (

        <section className="p-3 Welcome4 pb-0">
        <div className="container w-75 mt-1 yhrdiv"></div>
        <div className="h-100">
          <div className="container d-flex align-items-center justify-content-center h-100 w-75">
                {/* <p className="mb-3">If you do not know your access code, please call the portal's technical support department using the number for your country.</p>*/}
            

                <main className="form-signin">
                  <form>
                    <div className="row">
                      <div className="col-12 mx-auto">
                        <div className="row">
                          <div className="pb-1 col-12 col-md-2 my-auto blue">Log In</div>
                          <div className="pb-1 col-12 col-md-3 my-auto">
                            <label htmlFor="floatingInput" className="d-none form-label">Beneficiary Code</label>
                            <input type="text" className="form-control" id="floatingInput" placeholder="Company ID" value={this.state.login} onChange={this.handleChange1} />
                          </div>
                          <div className="pb-1 col-12 col-md-3 my-auto">
                            <label htmlFor="floatingPassword" className="d-none form-label">Please enter your password</label>
                            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={this.state.pwd} onChange={this.handleChange2} />
                          </div>
                          <div className="pb-1 col-12 col-md-4 my-auto">
                           <button className="w-100 btn btn-sm btn-primary" type="button" onClick={this.handleLoginClick}>Enter</button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 mx-auto pt-2 text-center">
                            <small className="">Please contact your company to get your country login details</small>
                          </div>
                        </div>
                      </div>
                    </div>

                  </form>
                </main>
                  <Alert key="0" variant="danger" show={showalert2}>
                    Your beneficiary code or password is incorrect.
                  </Alert>
                {/*<p className="mb-3"> This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility. </p>*/}
          

          </div>
        </div>
      </section>);
  }
}


export default Welcome2;