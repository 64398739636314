import React from 'react';
/*import ReactDOM from 'react-dom';*/
import logo from '../img/logo-stimulus-HD.jpg';
import headerimg1 from '../img/bh1.png';
import LanguageChange from './LanguageChange'
import Utils from './Utils'
import ReactGA from 'react-ga4';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

class Welcome1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langlist:[]
    };

  }

  componentDidMount = async () => {
    if(getCookieConsentValue())
    {
      ReactGA.send({ hitType: "pageview", page: "/LoggedOff" });
    }
    const r1 = await Utils.languesliste(this.state.token);

    if(r1 !== false)
    {
      this.setState({langlist: r1});
    }
  }
  render() {
    return (
      <>
        <div className="container-fluid p-0 position-aabsolute ydiv" id="Ydiv">
          <div className="container pt-3 w-75">
            <section className=" p-3 pt-0 mb-3 Welcome1 shadow-sm">
              <div className="h-100 mb-2">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between">
                  <a href="/" className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
                    <img src={logo} className='logol' alt="Logo Stimulus" />
                  </a>

                  {/*
                  <div className="col-12 col-sm-auto mb-3 mb-sm-0 me-sm-3">
                    <LanguageChange langlist={this.state.langlist} />
                  </div>
                  */}

                </div>
              </div>
              <div className="h-100">
                <div className="container d-flex align-items-center justify-content-center h-100">
                      <img src={headerimg1} className='headerimg1' alt="Your personalized Employee Assistance Program" />
                </div>
              </div>
            </section>
            

          </div>
        </div>
      </>
      );
  }
}


export default Welcome1;