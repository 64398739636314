import React from 'react';
/*import ReactDOM from 'react-dom';*/

import Alert from 'react-bootstrap/Alert';
import logo from '../img/logo-stimulus-HD.jpg';
import headerimg1 from '../img/bh1.png';
import LanguageChange from './LanguageChange'
import Utils from './Utils'


class Mentions extends React.Component {
  constructor(props) {
    super(props);
    this.handleGoBackClick = this.handleGoBackClick.bind(this);
    this.state = {
      langlist:[],
      isLoggedIn:this.props.isLoggedIn
    };
  }

  handleGoBackClick(e) {
    e.preventDefault();
    this.props.onGoBackClick();
  }



  componentDidMount = async () => {
    const r1 = await Utils.languesliste(this.state.token);

    if(r1 !== false)
    {
      this.setState({langlist: r1});
    }
  }

  render() {
    return (
    <>
      <div className="container-fluid p-0 position-aabsolute ydiv" id="Ydiv">
        <div className="container pt-3 w-75">
          <section className="p-3 pt-0 mb-3 Welcome1 shadow-sm">
            <div className="h-100 mb-2">
              <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between">
                <div className="col-6">
                {this.state.isLoggedIn ?
                  <a href="/" onClick={this.handleGoBackClick} className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
                    <img src={logo} className='logol' alt="Logo Stimulus" />
                  </a>
                :
                  <a href="/" className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
                    <img src={logo} className='logol mw-100' alt="Logo Stimulus" />
                  </a>
                }
                </div>
                {/*
                <div className="col-12 col-sm-auto mb-3 mb-sm-0 me-sm-3">
                  <LanguageChange langlist={this.state.langlist} />
                </div>
                */}
                <div className="col-6 col-sm-auto mb-3 mb-sm-0 me-sm-3 rtmenu text-end">
                  {this.state.isLoggedIn ?
                  <a href="/" onClick={this.handleGoBackClick}>Back to home</a>
                  :
                  <a href="/">Back to home</a>
                  }
                </div>
              </div>
            </div>
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100">
                    <img src={headerimg1} className='headerimg1' alt="Your personalized Employee Assistance Program" />
              </div>
            </div>
          </section>
          
        </div>
      </div>
        <div>
          <section className="p-3 mb-3 Welcome2">
            <div className="h-100">
              <div className="container mt-1 yhrdiv"></div>
              <div className="container d-flex align-items-center justify-content-center h-100 mt-1">
                    <h1 className="mb-3">Legal Information</h1>
              </div>
            </div>
          </section>

          <section className="p-3 mb-3 Welcome2">
            <div className="h-100">
              <div className="container d-flex align-items-left justify-content-left h-100">
                <p className="mb-3">1 - Site ownership</p>
<p className="mb-3">
This Site is the property of STIMULUS, a Simplified Joint Stock Company with a capital of 43,000 Euros, registered in the Paris Trade and Companies Register under number B 349 428 995 whose head office is located at 28, rue de Mogador, 75009 Paris, hereinafter “STIMULUS SAS”. The Site's publishing director is Mr. Emmanuel Charlot.
</p>
<p className="mb-3">2 - Site content</p>
<p className="mb-3">
The content of the Site ("Content") is understood to mean the general structure, the texts, the images, whether animated or not, and the sounds of which the Site is composed. Any total or partial representation of this Site and its Content, by any means whatsoever, without the express prior authorization of STIMULUS SAS, is unlawful according to Article L. 122.4 of the French Intellectual Property Code and would constitute an infringement punishable by by articles L. 335-2 et seq of this same code.
</p>
<p className="mb-3">3 - License to use the Site Content</p>
<p className="mb-3">
By simply connecting to the Site, the User acknowledges accepting from STIMULUS SAS a license to use the Content strictly limited to the following imperative conditions: The license is granted on a non-exclusive basis, The right of use conferred on the User is personal and private, which means that any reproduction of the Site Content on any medium for collective or professional use, even internally within the company, is prohibited. The same applies to any communication of this Content by electronic means, even disseminated on an intranet or a corporate extranet. This use only includes the authorization to reproduce for storage for the purposes of representation on a single-user screen and reproduction in one copy, for backup copy and hard copy. This use is subject to the prior express authorization of STIMULUS SAS. Violation of these provisions subjects the offender and all responsible persons to the criminal and civil penalties provided for by French law. 
</p>
<p className="mb-3">4 - Registration to the STIMULUS Platform</p>
<p className="mb-3">
Any registration to the platform made within the framework of this Site is subject to the General Conditions of Use.
</p>
<p className="mb-3">5 - Data Protection Act, files and freedoms</p>
<p className="mb-3">
In accordance with the law n ° 78-17 of January 6, 1978 relating to data processing, files and freedoms (“Data Protection Act”), the Site has been declared to the Commission Nationale de l’Informatique et des Libertés ("CNIL"). Any visitor to the STIMULUS SAS Site is required to comply with the provisions of the “Data Protection Act”, the violation of which is punishable by criminal penalties. Visitors must in particular refrain, with regard to the nominative information to which they access, from any collection, any misuse, and in general, any act likely to infringe the privacy or reputation of people. In accordance with articles 38 et seq. of the “Data Protection Act”, all visitors have the right to access, modify, rectify and delete data concerning them. Any visitor can exercise this right by sending a letter to STIMULUS SAS, 28, rue de Mogador, 75009 Paris or by sending an email to: <a href="mailto:contact@stimulus-conseil.com">contact@stimulus-conseil.com</a>.
</p>
<p className="mb-3">6 - Trademarks</p>
<p className="mb-3">
The STIMULUS, STIMULUS CARE SERVICES and HUMAN & WORK PROJECT brands appearing on the Site are registered trademarks. Any total or partial reproduction of these brands without the express authorization of STIMULUS SAS is therefore prohibited. You can contact us to make this type of request at the following email address <a href="mailto:contact@stimulus-conseil.com">contact@stimulus-conseil.com</a>.
</p>
<p className="mb-3">7 - Hosting and realization of the Site</p>
<p className="mb-3">
Design and production of the Site CG Consulting SAS VAT: FR 88 799451489 799 451 489 RCS Créteil Share capital: € 1,103,780.00 Site hosting is provided by the company: OVH SAS with capital of € 10,069,020 RCS Lille Métropole 424 761 419 00045 Code APE 2620Z VAT number: FR 22 424 761 419 Headquarters: 2 rue Kellermann - 59100 Roubaix - France.
                </p>
              
              </div>
            </div>
          </section>


          <div className="container">
            <p className="mb-3">{/* This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility. */}</p>
          </div>
        </div>
        </>
      )


  }
}


export default Mentions;