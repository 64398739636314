import React from 'react';
/*import ReactDOM from 'react-dom';*/
import Moment from 'moment';


class SearchScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleReadMoreClick2 = this.handleReadMoreClick2.bind(this);
  }

  handleReadMoreClick2(e) {
    e.preventDefault();
    this.props.ReadMoreClick2(e.currentTarget.getAttribute('data-value'), this.props.fiches);
  }

  render() {
    Moment.locale('en');
    const infos = this.props.infos;
    const fiches = this.props.fiches;
    const results = this.props.results;
    const query = this.props.query;
    return (
        <div>
          <section className="p-4 ArticleScreen">
            <div className="h-100">
              <div className="container">
                <div className="row">
                  <h1>Results for "{query}"</h1>
                </div>              
                <div className="row">
                  <div className="col-12">
                    <h6>{results.length} found</h6>
                  </div>
                </div>

                {
                  results.map(function (item) {
                    return (
                      <div className="row" key={"searchuniqueId_"+item.id}>

                        <div className="col-12">
                          <a href="" onClick={this.handleReadMoreClick2} data-value={item.id}>{item.titre}</a>
                        </div>  
                        <div className="col-12">
                          <small>{item.texte.substring(0, 200)}...</small>
                        </div>         
                      </div>
                    );
                  }, this)
                }
              </div>
            </div>
          </section>

          <div className="container">
            <p className="mb-3">{/* This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility. */}</p>
          </div>
        </div>
      );
  }
}


export default SearchScreen;