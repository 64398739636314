import React from 'react';
/*import ReactDOM from 'react-dom';*/
import Moment from 'moment';


class ArticleScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleNextClick = this.handleNextClick.bind(this);
  }

  handleNextClick(e) {
    this.props.onButtonClick(e.target.value);
  }
  render() {
    Moment.locale('en');
    const article = this.props.article;
    return (
        <div>
          <section className="p-4 ArticleScreen">
            <div className="h-100">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h6>{Moment(article[0].date).format('MMMM DD, YYYY')}</h6>
                  </div>
                </div>
                <div className="row">
                  <h1>{article[0].titre}</h1>
                </div>
                
                <div className="row">

                  <div className="col-12">
                    <img src={"https://erp.psya.fr"+article[0].img} alt={article[0].titre} />

                    <span>
                      {article[0].texte}
                    </span>

                  </div>  
                              
                </div>
              </div>
            </div>
          </section>

          <div className="container">
            <p className="mb-3">{/* This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility. */}</p>
          </div>
        </div>
      );
  }
}


export default ArticleScreen;