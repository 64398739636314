import React from 'react';
/*import ReactDOM from 'react-dom';*/
import noimgpdf from '../img/42.png';
import noimgpodcast from '../img/39.png';
import noimgvideo from '../img/40.png';

class PracticalInformations extends React.Component {
  constructor(props) {
    super(props);

    this.handleReadMoreClick2 = this.handleReadMoreClick2.bind(this);

  }

  handleReadMoreClick2(e) {
    this.props.ReadMoreClick2(e.currentTarget.getAttribute('data-value'), this.props.fiches);
  }

  render() {
    const fiches=this.props.fiches; 
    const preprod="";
    if (fiches.length > 0) {
    return (
          <section className="p-4 LoggedScreen4 bggreylight">
            <div className="h-100">
              <div className="container">
                <h2 className="mb-3 blue">About your services</h2>
                <p className="grey">Your communication material</p>
                <div className="row">
                  {
                    fiches.reverse().map(function (item) {
                      return (
                        <div className="col-12 col-sm-6 col-md-4" key={"uniqueId_"+item.id}>
                          <article className="mb-3">
                            {( (item.pdf !== "") && (item.pdf)) &&
                            <a href={"https://"+preprod+"erp.psya.fr"+item.pdf} target="_blank">
                              <img src={"https://"+preprod+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} altsrc={noimgpdf}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=currentTarget.getAttribute("altsrc");
                              }}
                               />
                            </a>
                            }
                            {( (item.podcast !== "") && (item.podcast)) &&
                            <a href={item.podcast} target="_blank">
                              <img src={"https://"+preprod+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} altsrc={noimgpodcast}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=currentTarget.getAttribute("altsrc");
                              }}
                               />
                            </a>
                            }
                            {( (item.video !== "") && (item.video)) &&
                            <a href={item.video} target="_blank">
                              <img src={"https://"+preprod+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} altsrc={noimgvideo}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=currentTarget.getAttribute("altsrc");
                              }}
                               />
                            </a>
                            }

                            {( ( (item.video === "") || (!item.video) ) && ( (item.podcast === "") || (!item.podcast) ) && ( (item.pdf === "") || (!item.pdf) ) ) &&
                            <a href={"https://"+preprod+"erp.psya.fr"+item.img} target="_blank">
                              <img src={"https://"+preprod+"erp.psya.fr"+item.img} className="mb-2" alt={item.titre} />
                            </a>
                            }

                            {/* <h6 className="mb-3">{item.titre}</h6>
                            <div className="col-12"><small>{item.texte.substring(0, 200)}...</small></div>
                            <button className="mt-3 ps-5 pe-5 btn btn-lg btn-primary" type="button"  onClick={this.handleReadMoreClick2} data-value={item.id}>Read More</button>
                            */}
                          </article>
                        </div>);
                    }, this)
                  }

                </div>
              </div>
            </div>
          </section>
        );
    } else {
      return (<div></div>);
    }
  }
}


export default PracticalInformations;