import React from 'react';
/*import ReactDOM from 'react-dom';*/

import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import FloatingLabel  from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class QuestionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.handleSendQuestionClick = this.handleSendQuestionClick.bind(this);
    this.handleChangeQ1 = this.handleChangeQ1.bind(this);
    this.handleChangeQ2 = this.handleChangeQ2.bind(this);
    this.handleChangeQ3_date = this.handleChangeQ3_date.bind(this);
    this.handleChangeQ3_time = this.handleChangeQ3_time.bind(this);
    this.handleChangeQ4 = this.handleChangeQ4.bind(this);
    this.handleChangeQ5 = this.handleChangeQ5.bind(this);
    this.handleChangeQ6 = this.handleChangeQ6.bind(this);
    this.handleChangeQ7 = this.handleChangeQ7.bind(this);
    this.handleChangeQ8 = this.handleChangeQ8.bind(this);
    this.handleChangeQ9 = this.handleChangeQ9.bind(this);
    this.handleChangeQ10 = this.handleChangeQ10.bind(this);

    this.state = {activei:-1, q0:['','','',''], q2:'', q3_date:'', q3_time:'', q4: '', q5: '', q6: '', q7: '', q8: "I don't know", q9: '', q10: '', alertmsg:''};

  }

  handleChangeQ1(e, k) {

    this.setState(state => {
      const q0 = state.q0.map((item, j) => {
        if (j === k) {
          if(e.checked)
          {
            return e.value;
          } else {
            return '';
          }
        } else {
          return item;
        }
      });

      return {
        q0,
      };
    });
  };

  handleChangeQ2(e) {
    this.setState({q2: e});
  };

  handleChangeQ3_date(e) {
    this.setState({q3_date: e});
  };

  handleChangeQ3_time(e) {
    this.setState({q3_time: e});
  };

  handleChangeQ4(e) {
    this.setState({q4: e});
  };
  handleChangeQ5(e) {
    this.setState({q5: e});
  };
  handleChangeQ6(e) {
    this.setState({q6: e});
  };
  handleChangeQ7(e) {
    this.setState({q7: e});
  };
  handleChangeQ8(e) {
    this.setState({q8: e});
  };

  handleChangeQ9(e) {
    this.setState({q9: e});
    this.setState({activei: e});
  };

  handleChangeQ10(e) {
    this.setState({q10: e});
  };


  handleSendQuestionClick(e) {
    this.props.onSendQuestionClick(this.state.q0, this.state.q2, this.state.q3_date, this.state.q3_time, this.state.q4, this.state.q5, this.state.q6, this.state.q7, this.state.q8, this.state.q9, this.state.q10);
  }
  render() {
    const showalertquestiondanger = this.props.showalertquestiondanger;
    const showalertquestiondanger_msg = this.props.showalertquestiondanger_msg;
    const showalertquestionsuccess = this.props.showalertquestionsuccess;

    return (
        <div>
          <section className="p-3 mb-3 Welcome2">
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100">
                    <h1 className="mb-3">Your feedback is important!</h1>

                    <p>Please take a few minutes to complete this survey to help us improve the quality of our services. </p>
                    <p>Thank you for your participation.</p>
                    <p><i>Your answers will remain anonymous.</i></p>
              </div>
            </div>
          </section>

          <section className="p-3 mb-3 Welcome2">
            <div className="h-100">
              <div className="container d-flex align-items-center justify-content-center h-100">
                    <p className="mb-3">If you do not know your access code, please call the portal's technical support department using the number for your country.</p>
              

                    <main className="form-contact-question mb-3">
                      <form>

                        <div className="mb-3">
                          Please indicate the service(s) you have requested: (several choices possible)
                          <div className="form-check form-check-inline">
                          <Form.Check
                            inline
                            label="Psychological support"
                            name="q0"
                            type="checkbox"
                            id="fuPhone"
                            value="Psychological support"
                            onChange={(e) => {
                              this.handleChangeQ1(e.target, 0);
                            }}
                          />
                          <Form.Check
                            inline
                            label="Financial support"
                            name="q0"
                            type="checkbox"
                            id="fuPhone"
                            value="Financial support"
                            onChange={(e) => {
                              this.handleChangeQ1(e.target, 1);
                            }}
                          />
                          <Form.Check
                            inline
                            label="Legal information"
                            name="q0"
                            type="checkbox"
                            id="fuPhone"
                            value="Legal information"
                            onChange={(e) => {
                              this.handleChangeQ1(e.target, 2);
                            }}
                          />
                          <Form.Check
                            inline
                            label="Other"
                            name="q0"
                            type="checkbox"
                            id="fuPhone"
                            value="Other"
                            onChange={(e) => {
                              this.handleChangeQ1(e.target, 3);
                            }}
                          />
                          </div>
                        </div>


                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingSelect" label="Modalities: How was the support provided?">
                            <Form.Select required aria-label="Modalities: How was the support provided?" onChange={(e: any) => this.handleChangeQ2(e.currentTarget.value)}>
                              <option value="">-</option>
                              <option value="Support by phone">Support by phone</option>
                              <option value="Video-consultation">Video-consultation</option>
                              <option value="Face to face consultation">Face to face consultation</option>
                              <option value="Other">Other</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>

                        <div className="mb-3 form-floating">
                          Date and time of the session<br />
                          <div className="row">
                            <div className="col-6">
                              <Form.Control
                                required
                                type="date"
                                name="ddate"
                                placeholder="Date"
                                value={this.state.q3_date}
                                 onChange={(e: any) => this.handleChangeQ3_date(e.currentTarget.value)}
                              />
                            </div>
                            <div className="col-6">
                              <Form.Control
                                required
                                type="time"
                                name="dtime"
                                placeholder="Time"
                                value={this.state.q3_time}
                                 onChange={(e: any) => this.handleChangeQ3_time(e.currentTarget.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingSelect" label="What is your overall satisfaction with the service?">
                            <Form.Select required aria-label="What is your overall satisfaction with the service?" onChange={(e: any) => this.handleChangeQ4(e.currentTarget.value)}>
                              <option value="">-</option>
                              <option value="Very Satisfied">Very Satisfied</option>
                              <option value="Satisfied">Satisfied</option>
                              <option value="Somewhat satisfied">Somewhat satisfied</option>
                              <option value="Not very satisfied">Not very satisfied</option>
                              <option value="Not at all satisfied">Not at all satisfied</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>

                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingSelect" label="As regards the availability of the service, would you say that you are:">
                            <Form.Select aria-label="As regards the availability of the service, would you say that you are:" onChange={(e: any) => this.handleChangeQ5(e.currentTarget.value)}>
                              <option>-</option>
                              <option value="Very Satisfied">Very Satisfied</option>
                              <option value="Satisfied">Satisfied</option>
                              <option value="Somewhat satisfied">Somewhat satisfied</option>
                              <option value="Not very satisfied">Not very satisfied</option>
                              <option value="Not at all satisfied">Not at all satisfied</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>

                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingSelect" label="As regards the listening skills of the professional during the interview, would you say that you are:">
                            <Form.Select aria-label="As regards the listening skills of the professional during the interview, would you say that you are:" onChange={(e: any) => this.handleChangeQ6(e.currentTarget.value)}>
                              <option>-</option>
                              <option value="Very Satisfied">Very Satisfied</option>
                              <option value="Satisfied">Satisfied</option>
                              <option value="Somewhat satisfied">Somewhat satisfied</option>
                              <option value="Not very satisfied">Not very satisfied</option>
                              <option value="Not at all satisfied">Not at all satisfied</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>

                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingSelect" label="As regards the advice and/or tools offered, would you say that you are:">
                            <Form.Select aria-label="As regards the advice and/or tools offered, would you say that you are:" onChange={(e: any) => this.handleChangeQ7(e.currentTarget.value)}>
                              <option>-</option>
                              <option value="Very Satisfied">Very Satisfied</option>
                              <option value="Satisfied">Satisfied</option>
                              <option value="Somewhat satisfied">Somewhat satisfied</option>
                              <option value="Not very satisfied">Not very satisfied</option>
                              <option value="Not at all satisfied">Not at all satisfied</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>

                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingSelect" label="Would you use one of our services again if you needed it?">
                            <Form.Select aria-label="Would you use one of our services again if you needed it?" onChange={(e: any) => this.handleChangeQ8(e.currentTarget.value)}>
                              <option value="I don't know">I don't know</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </FloatingLabel>
                        </div>

                        <div className="mb-3">
                          How likely is it that you would recommend our services to a colleague or relative?
                          <InputGroup className="mb-3 form-floating">
                              <InputGroup.Text>not at all likely</InputGroup.Text>
                              <Button variant={this.state.activei === 0 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(0)}>0</Button>
                              <Button variant={this.state.activei === 1 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(1)}>1</Button>
                              <Button variant={this.state.activei === 2 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(2)}>2</Button>
                              <Button variant={this.state.activei === 3 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(3)}>3</Button>
                              <Button variant={this.state.activei === 4 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(4)}>4</Button>
                              <Button variant={this.state.activei === 5 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(5)}>5</Button>
                              <Button variant={this.state.activei === 6 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(6)}>6</Button>
                              <Button variant={this.state.activei === 7 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(7)}>7</Button>
                              <Button variant={this.state.activei === 8 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(8)}>8</Button>
                              <Button variant={this.state.activei === 9 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(9)}>9</Button>
                              <Button variant={this.state.activei === 10 ? "outline-secondary active" : "outline-secondary"} onClick={(e: any) => this.handleChangeQ9(10)}>10</Button>
                              <InputGroup.Text>very likely</InputGroup.Text>
                              
                          </InputGroup>
                        </div>

                        <div className="mb-3 form-floating">
                          <FloatingLabel controlId="floatingTextarea2" label="Do not hesitate to share comments:">
                            <Form.Control
                              as="textarea"
                              placeholder="Leave a comment here"
                              style={{ height: '100px' }}
                              onChange={(e: any) => this.handleChangeQ10(e.currentTarget.value)}
                            />
                          </FloatingLabel>
                        </div>

                        <button className="mb-3 w-100 btn btn-lg btn-primary" type="button" onClick={this.handleSendQuestionClick}>Send feedback</button>
                      </form>
                    </main>
                      <Alert className="notranslate" key="alert_5" variant="danger" show={showalertquestiondanger}>
                        {showalertquestiondanger_msg}
                      </Alert>
                      <Alert className="notranslate" key="alert_1" variant="success" show={showalertquestionsuccess}>
                        Thank you for your feedback!<br />
                        Should you like to continue to discuss your experience with us, our team is at your disposal: <a href="mailto:survey@stimulus-care-services.com">survey@stimulus-care-services.com</a>
                      </Alert>
                    

              </div>
            </div>
          </section>


          <div className="container">
            <p className="mb-3">{/* This program is a listening ear but should not be substituted for a doctor's or professional's care. In case of an emergency, call the local emergency services phone number at your location, or go to the nearest ambulatory and urgent care facility. */}</p>
          </div>
        </div>
      );
  }
}


export default QuestionScreen;