import React from 'react';
/*import ReactDOM from 'react-dom';*/

import li from '../img/linkedin.png';
import fb from '../img/facebook.png';
import tw from '../img/twitter.png';

class FooterApp extends React.Component {
  constructor(props) {
    super(props);
    this.handleMentionsClick = this.handleMentionsClick.bind(this);
    this.handleContactUsClick = this.handleContactUsClick.bind(this);
    this.handleQuestionClick = this.handleQuestionClick.bind(this);
  }

  handleMentionsClick(e) {
    e.preventDefault();
    this.props.onMentionsClick();
  }

  handleContactUsClick(e) {
    e.preventDefault();
    this.props.onContactUsClick();
  }

  handleQuestionClick(e) {
    e.preventDefault();
    this.props.onQuestionClick();
  }

  render() {
    const isLoggedIn=this.props.isLoggedIn; 
    const step2=this.props.step2; 
    return (
      <footer className="footer pt-4">
        {!isLoggedIn &&
          <>
          <div className="bgblue pt-3 pb-3 mb-3 mhfooter">
            {!step2 &&
              <>
                <div className="container w-75 text-white">
                  <h2 className="text-uppercase">About<span className="notranslate"> Stimulus</span></h2>
                  <p>A global approach to wellbeing and psychological health at work.<br />To find out more about<span className="notranslate"> Stimulus </span>and our group<span className="notranslate"> Human & Work</span>, visit our website<span className="notranslate"> <a href="https://www.stimulus-global.com" target="_blank">Stimulus Global</a></span></p>
                </div>
              </>
            }
          </div>
          </>
        }
        <div className="container d-flex flex-wrap align-items-center justify-content-between w-75 w-sm-100">
          <div className="fd1">
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3">&copy; 2022<span className="notranslate"> Stimulus</span></span>
            <span className="d-none d-sm-inline ms-3 me-3">|</span>
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="/" onClick={this.handleMentionsClick}>Legal information</a></span>
            <span className="d-none d-sm-inline ms-3 me-3">|</span>
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="https://stimulus-global.com/data-protection-policy/" target="_blank">Privacy policy</a></span>
            <span className="d-none d-sm-inline ms-3 me-3">|</span>
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="https://www.stimulus-global.com" target="_blank"><span className="notranslate">Stimulus Global</span></a></span>
            <span className="d-none d-sm-inline ms-3 me-3">|</span>
            {!isLoggedIn &&
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="mailto:globalnetwork@stimulus-conseil.com">Contact us</a></span>
            }
            {isLoggedIn &&
            <>
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="/" onClick={this.handleContactUsClick}>Contact us</a></span>
            {/*<span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="mailto:globalnetwork@stimulus-conseil.com">Contact us</a></span>*/}
            <span className="d-none d-sm-inline ms-3 me-3">|</span>
            <span className="d-block d-sm-inline ms-sm-3 me-sm-3"><a href="/" onClick={this.handleQuestionClick}>Feedback</a></span>
            </>
            }
          </div>

          <div className="fd2">
            <a href="https://www.linkedin.com/company/stimulusglobal/?viewAsMember=true" target="_blank" rel="noreferrer"><img src={li} className=" ms-1 me-1" alt="Linkedin Stimulus" /></a>
            {/*<a href="https://www.facebook.com/stimulusconseil/" target="_blank" rel="noreferrer"><img src={fb} className=" ms-1 me-1" alt="Facebook Stimulus" /></a>*/}
            <a href="https://twitter.com/StimulusGlobal_" target="_blank" rel="noreferrer"><img src={tw} className=" ms-1 me-1" alt="Twitter Stimulus" /></a>
          </div>
        </div>
      </footer>);
  }
}

export default FooterApp;