import React from 'react';
/*import ReactDOM from 'react-dom';*/

import logo from '../img/logo-stimulus-HD.jpg';
import weare from '../img/weare.png';
import { FaSearch } from 'react-icons/fa';
import LanguageChange from './LanguageChange'
import Utils from './Utils'
import ReactGA from 'react-ga4';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

class HeaderOn extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleChangeLocationClick = this.handleChangeLocationClick.bind(this);
    this.handleContactUsClick = this.handleContactUsClick.bind(this);
    this.handleGoBackClick = this.handleGoBackClick.bind(this);
    this.handleChangeCountryClick = this.handleChangeCountryClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = {
      cylistw3:this.props.cylist,
      token:this.props.token,
      iuser:this.props.iuser,
      clogo:this.props.clogo,
      curl:this.props.curl,
      query:'',
      langlist:[]
    };

  }

  handleChangeCountryClick(e) {
    this.props.onChangeCountryClick(e.target.value, this.state.cylistw3);
  }

  handleSearchChange(event) {
    this.setState({query: event.target.value});
  }

  handleSearchClick(e) {
    this.props.onSearchClick(this.state.query);
  }

  handleKeySearchClick(e) {
    if(e.key==='Enter')
    {
      this.props.onSearchClick(this.state.query);
    } else {
      e.preventDefault();
    }    
    
  }

  handleLogoutClick(e) {
    e.preventDefault();
    this.props.onButtonClick();
  }

  handleChangeLocationClick(e) {
    e.preventDefault();
    this.props.onChangeLocationClick(this.state.iuser);
  }

  handleContactUsClick(e) {
    e.preventDefault();
    this.props.onContactUsClick();
  }

  handleGoBackClick(e) {
    e.preventDefault();
    this.props.onGoBackClick();
  }

  componentDidMount = async () => {
    if(getCookieConsentValue())
    {
      ReactGA.send({ hitType: "pageview", page: "/LoggedOn" });
    }
    const r1 = await Utils.languesliste(this.state.token);

    if(r1 !== false)
    {
      this.setState({langlist: r1});
    }

  }

  render() {
    const cylist=this.props.cylist;
    const infos=this.props.infos;  
    const iuser=this.props.iuser;  
    const showmain=this.props.showmain;  
    const showcontact=this.props.showcontact;  
    const showarticle=this.props.showarticle;    
    const showsearch=this.props.showsearch;
    const clogo = this.props.clogo;
    const curl = this.props.curl;
    return (

        <header className="pt-3 pb-3 mb-0 App-header">
          <div className="ah1">
            <div className="container pb-3">
              <div className="d-none w-100 d-sm-flex flex-wrap align-items-center justify-content-between justify-content-sm-between">
                <div className="d-flex justify-content-start">
                  
                    {/* <a href="#" onClick={this.handleGoBackClick} className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none"> */}
                    <a href="https://www.stimulus-conseil.com/" target="_blank" className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
                    <img src={logo} className="mb-2 logo" alt="Logo Stimulus" />
                    </a>

                </div>

                {( (clogo !== undefined && clogo!="" && clogo!="0") && (curl !==undefined && curl!="" )) &&
                <div className="d-flex justify-content-start">
                <a href={curl} className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none" target="_blank">
                <img src={clogo} className="mb-2 logo" alt="Logo" />
                </a>
                </div>
                }
                {( (clogo !== undefined && clogo!="" && clogo!="0") && (curl ===undefined || curl=="" )) &&
                <div className="d-flex justify-content-start">
                <img src={clogo} className="mb-2 logo" alt="Logo" />
                </div>
                }

                {/*
                <form className="col-12 col-sm-4 me-sm-3" onSubmit={e => { e.preventDefault(); }}>
                  <div className="input-group">
                    <input type="text" onChange={this.handleSearchChange} className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <button className="btn btn-primary" onClick={this.handleSearchClick} type="button" id="button-addon2"><FaSearch /></button>
                  </div>
                </form>
                */}

                <div className="d-flex justify-content-end">
                {!showmain &&
                  <a href="/" onClick={this.handleGoBackClick} className="d-flex align-items-center me-4 bluelink">
                    Back home
                  </a>
                }
                  <a href="/" onClick={this.handleChangeLocationClick} className="d-flex align-items-center me-4 bluelink">
                    Change my location
                  </a>
                  <a href="/" onClick={this.handleLogoutClick} className="d-flex align-items-center">
                    Log out
                  </a>
                </div>

                {/*
                  {showmain &&
                    <a href="/" onClick={this.handleContactUsClick} className="d-flex align-items-center mb-2 mb-sm-0 ">
                      Contact us
                    </a>
                  }
                  {showcontact && false===true &&
                    <a href="/" onClick={this.handleGoBackClick} className="d-flex align-items-center mb-2 mb-sm-0 ">
                      Go back
                    </a>
                  }
                  {showarticle && 
                    <a href="/" onClick={this.handleContactUsClick} className="d-flex align-items-center mb-2 mb-sm-0 ">
                      Contact us
                    </a>
                  }
                  {showsearch && 
                    <a href="/" onClick={this.handleContactUsClick} className="d-flex align-items-center mb-2 mb-sm-0 ">
                      Contact us
                    </a>
                  }
                */}
               
              </div>

              <div className="d-sm-none w-100 d-flex flex-wrap align-items-center justify-content-between justify-content-sm-between">
                <div className="d-flex justify-content-start">
                  
                    <a href="#" onClick={this.handleGoBackClick} className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none">
                    <img src={logo} className="mb-2 logo" alt="Logo Stimulus" />
                    </a>

                </div>

                {( (clogo !== undefined && clogo!="" && clogo!="0") && (curl !==undefined && curl!="" )) &&
                <div className="d-flex justify-content-start">
                <a href={curl} className="d-flex align-items-center mb-2 mb-sm-0 text-dark text-decoration-none" target="_blank">
                <img src={clogo} className="mb-2 logo" alt="Logo" />
                </a>
                </div>
                }
                {( (clogo !== undefined && clogo!="" && clogo!="0") && (curl ===undefined || curl=="" )) &&
                <div className="d-flex justify-content-start">
                <img src={clogo} className="mb-2 logo" alt="Logo" />
                </div>
                }

              </div>

              <div className="d-sm-none w-100 d-flex flex-wrap align-items-end justify-content-end justify-content-sm-end">
                <div className="d-flex justify-content-end">
                  <a href="/" onClick={this.handleChangeLocationClick} className="d-flex align-items-center me-4 bluelink">
                    Change my location
                  </a>
                  <a href="/" onClick={this.handleLogoutClick} className="d-flex align-items-center">
                    Log out
                  </a>
                </div>
              </div>

            </div>
          </div>

            <div className="container mt-3 pe-4 pe-sm-0">
              <div className="d-flex flex-wrap align-items-end justify-content-end justify-content-sm-end">


                {/* <form className="col-12 col-sm-auto mb-3 mb-sm-0 me-sm-0 ms-sm-3">
                  <select className="form-select" defaultValue={infos[0].id} onChange={this.handleChangeCountryClick}>
                      {
                        cylist.map(function (item) {
                          return <option key={"thekey_"+item.id} value={item.id}>{item.name}</option>
                        })
                      }
                  </select>
                </form>*/}

                <div className="col-12 col-sm-6 col-xl-5 mb-0 mb-sm-0 mb-sm-0 me-sm-0 ms-sm-0 blue">
                  {/*<h1>We are by your side</h1>*/}
                  <img src={weare} className='mxh-75 mw-100' alt="We are by your side" />
                </div>

                {/*
                <div className="col-12 col-sm-auto mb-3 mb-sm-0 me-sm-0 ms-sm-0">
                  <LanguageChange langlist={this.state.langlist} />
                </div>
                */}
                
                {/*
                <div className="col-12 col-sm-auto mb-0 mb-sm-3 mb-sm-0 me-sm-0 ms-sm-0 ">
                  <a href="/" onClick={this.handleChangeLocationClick} className="rtmenu d-flex justify-content-end align-items-center mb-2 mb-sm-0 me-0">
                    Change my location
                  </a>
                </div>
                */}


              </div>
            </div>
          
        </header>);
  }
}


export default HeaderOn;